import React, { CSSProperties, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import './viewer-canvas.scss';
import { RouteComponentProps } from 'react-router';
import {
  deletePanoramaEdited,
  getPanoramas,
  reprocessPanorama,
  savePanoramaEdited,
  updatePanorama,
  deletePanorama,
  getBlurSuggestion,
} from '../../api-helper/api-panoramas';
import { useHistory } from 'react-router';
import EditImage from './modals/edit-image';
import AddNote from './modals/add-note';
import AddImage from './modals/add-image';
import EditHotspot from './modals/edit-hotspot';
import AddAnnotation from './modals/add-annotation';
import Settings from './modals/settings';
import StillImage from './modals/still-image';
import LeftSidebar from './left-sidebar';
import Header from './header';
import {
  createPanoramaAnnotation,
  updatePanoramaAnnotation,
  deletePanoramaAnnotation,
  getPanoramaAnnotations,
} from '../../api-helper/api-panorama-annotation';
// Viewer
import { GenerateUUID, Viewer, mapHotspotValuesPlatform } from '@vva/pannellum-viewer';
import { IViewerConfig } from '@vva/pannellum-viewer/lib/interfaces/IViewerConfig';
import { IHotspot } from '@vva/pannellum-viewer/lib/interfaces/IHotspot';
import { newCreateListReducer } from '../viewer/listReducerAnnotations';
import { getHotspotsPlatform } from '../../api-helper/api-hotspots';
import SuccessNotification from '../utils/notifications/success-notification';
import { getTour, shareTour } from '../../api-helper/api-tours';
import PanoramaSlider from './panoramas-slider';
import AddHotspot from './modals/add-hotspot';
import { useModal } from './modals/useModal/useModal';
import { default as ShareModal } from '../utils/modal/modal';
import {
  getCorrectedYaw,
  getHotspotAnnotationObject,
  getHotspotImageObject,
  getHotspotLinkObjectNoToolTip,
  getHotspotNoteObject,
  getWrongYaw,
} from './viewer-canvas-utils/hotspot-factory';
import { defaultLeftSidebar, ILeftSidebar, openLink } from './viewer-canvas-utils/viewer-canvas-helper';
import LinksMap from './modals/links-map';
import { useConfig } from '../utils/user-config-context/user-config-context';
import starIcon from '../../images/viewer-icons/start-white.svg';
import infoIcon from '../../images/viewer-icons/info.svg';
import tickIcon from '../../images/viewer-icons/tick-success.svg';
import { Trans } from '@lingui/react';
import AddBlur from './modals/add-blur';
import { newCreateListReducerBlur } from '../viewer/listReducerAnnotationsBlur';
import { ReportCustomEvent, ReportPageView } from '../../GoogleAnalyticsConfig';
import { EnumCategory, EnumEvent, EnumPagesTitles } from '../../google-analytics-track-list';
//! tooltip
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { tooltipsData } from '../utils/tooltips/tooltips-data';
import {
  IAnnotationTypesConfig,
  IHotspotCreation,
  INewAnnotation,
  IPanorama,
  IPanoramaAnnotation,
  IPanoramaOptions,
  IUpdateAnnotation,
} from '../../api-helper/interface/interfaces';
import { IAnnotation, ILocalHotspotCreation, IObjectString, IReducerHotspot } from './interaces';
import LoadingSpinnerNoBg from '../utils/loading-spinner/loading-spinner-no-bg';
import { createArrayCopyNoReference } from '../utils/array-utils/array-utils';
import { IPresetValues } from '../utils/classes/image-builder';
import Notification, { EnumNotificationSize, EnumNotificationType } from '../utils/notifications/notification';
import { handleError } from '../../api-helper/api-error-handling';
import { useAuth } from '../../auth-routes/auth-context';
import Modal from './modals/useModal/Modal';
import AddAudio from './modals/add-audio';
import { linguiTranslateToString } from '../utils/lingui-utils/utils-lingui';
import LoadingSpinner from '../utils/loading-spinner/loading-spinner';

// Viewer object
let viewer: any;

export interface ISidebar {
  [key: string]: boolean;
}

const EditingViewer = ({ location }: RouteComponentProps<any>) => {
  const { handleLogoutState } = useAuth();
  const config = useConfig();
  const history = useHistory();

  const previousLocationHistory: any = location.state;

  const [tour, setTour] = useState<any>();
  const [editedImage, setEditedImage] = useState<string[]>();
  const [currentPanorama, setCurrentPanorama] = useState<any>({});
  const [panoramaHdState, setPanoramaHdState] = useState<boolean>(false);
  const [panoramaData, setPanoramaData] = useState<any>();
  const [selectedHotspot, setSelectedHotspot] = useState<any>();
  const [viewerLoaded, setViewerLoaded] = useState<boolean>(false);
  const [toggleSuccess, setToggleSuccess] = useState<boolean>(false);
  const [noteHotspot, setNoteHotspot] = useState<any>();
  const [stillImageHotspot, setStillImageNoteHotspot] = useState<any>();
  const [newHotspot, setNewHotspot] = useState<any>(undefined);
  const [newHotspotModal, setNewHotspotModal] = useState<boolean>(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [addImageModal, setAddImageModal] = useState(false);
  const [updateOnModalClose, setUpdateOnModalClose] = useState<boolean>(false);
  const [annotateActive, setAnnotateActive] = useState<boolean>(false);
  const [blurActive, setBlurActive] = useState<boolean>(false);
  const [noteEdit, setNoteEdit] = useState<boolean>(false);
  const [mediaEdit, setMediaEdit] = useState(false);
  const [leftSidebar, setLeftSidebar] = useState<ILeftSidebar>(defaultLeftSidebar);
  const [previousWordSearch, setPreviousWordSearch] = useState<string>();
  const [dashboardView, setDashboardView] = useState('');
  const [orderOptionName, setOrderOptionName] = useState<string>();
  const [orderOption, setOrderOption] = useState<string>();
  const [previousPaginationPage, setPreviousPaginationPage] = useState<number>();
  const [previousTourIndex, setPreviousTourIndex] = useState<number>();
  const [reprocessingPanorama, setReprocessingPanorama] = useState<boolean>(false);
  const [reprocessForImageEdit, setReprocessForImageEdit] = useState<boolean>(false);
  const [panoramaDeleteResponse, setPanoramaDeleteResponse] = useState<any>();
  const [loadingSuggestion, setLoadingSuggestion] = useState<IObjectString>({ state: 'default' });
  const [blurAnnotationsToDelete, setBlurAnnotationsToDelete] = useState<string[]>([]);
  const [position, setPosition] = useState<number>(0);
  const [fieldOfView, setFieldOfView] = useState<number>(0);
  const [blurResponse, setBlurResponse] = useState<any>();
  const [imageOptionsResponse, setImageOptionsResponse] = useState<IPresetValues>({});
  const [presetValues, setPresetValues] = useState<IPresetValues>({});
  const [isLoading, setIsLoading] = useState(false);
  const [renderImagePresets, setRenderImagePresets] = useState<boolean>(false);
  const [targetYaw, setTargetYaw] = useState<number>();

  // Tooltip
  const [isTooltipActive, setIsTooltipActive] = useState<boolean>(false);
  const [currentTooltipStep, setCurrentTooltipStep] = useState<number>(0);
  const tooltipSteps = tooltipsData;

  // Notification
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeNotification, setTypeNotification] = useState<EnumNotificationType>();
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationDuration, setNotificationDuration] = useState(2000);
  const [sidebar, setSidebar] = useState<ISidebar>({
    tripodBranding: false,
    photoEnhancement: false,
    panoramaBlur: false,
    faceDetection: false,
    leadGeneration: false,
    proLogo: false,
    audio: false,
    autoEnhance: false,
    autoBlur: false,
    autoTripodRemoval: false,
    autoAlignment: false,
  });
  const [isBeta, setIsBeta] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('beta') === '1') {
      setIsBeta(true);
    } else {
      setIsBeta(false);
    }
  }, []);

  const toggleNotification = (type: EnumNotificationType, message?: string, duration?: number) => {
    if (duration) {
      setNotificationDuration(8000);
    } else {
      setNotificationDuration(2000);
    }
    setTypeNotification(type);
    setNotificationMessage(message || linguiTranslateToString('There was an error please try again'));
    setShowNotification(true);
  };

  const onExit = () => {
    setIsTooltipActive(false);
  };

  const onStepsCompleted = () => {
    setCurrentTooltipStep(0);
  };

  const stepsInfo = (currentStep: number) => {
    setCurrentTooltipStep(currentStep);
  };

  const activeTooltipHandler = () => {
    //Report Custom Category and Event
    ReportCustomEvent(EnumCategory.Tooltip, EnumEvent.TooltipStudio);
    setIsTooltipActive(!isTooltipActive);
  };

  const resetPresets = async () => {
    changeLeftSidebar('');
    setEditedImage([]);
    setIsLoading(true);
    setPresetValues({});

    try {
      await deletePanoramaEdited(currentPanorama.id);
      await reprocessPanorama(currentPanorama.id);
      setReprocessForImageEdit(true);
      updateAfterProcessing();
      // Display notification message
      setTimeout(() => {
        setReprocessForImageEdit(false);
      }, 5000);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const toggleSuccessNotification = (message: string) => {
    setNotificationMessage(message);
    setToggleSuccess(!toggleSuccess);
  };

  useEffect(() => {
    //Google report page
    ReportPageView(EnumPagesTitles.Editor);
  }, []);

  useEffect(() => {
    if (config && config.modules) {
      let sidebarTemp = { ...sidebar };
      for (const menu of config.modules) {
        const menuKey = Object.keys(menu);
        const key = sidebar[menuKey[0]];
        // if there is a config for a left sidebar option add it
        if (key !== undefined) {
          sidebarTemp[menuKey[0]] = menu[menuKey[0]];
        }
        setSidebar(sidebarTemp);
      }
    }
  }, [config]);

  const toggleNewHotspotModal = () => {
    setNewHotspotModal(!newHotspotModal);
  };

  const toggleAnnotation = () => {
    setAnnotateActive(!annotateActive);
    onViewerAction('annotate');
  };

  const toggleBlur = (end?: boolean) => {
    if (end) {
      if (objectBlur.length >= 3) {
        setBlurActive(false);
        onViewerActionBlur('annotate');
      }
    } else {
      setBlurActive(true);
      onViewerActionBlur('annotate');
    }
  };

  const changeLeftSidebar = (option: string) => {
    if (viewer) {
      const coordinates = viewer.getCoordinates();
      setFieldOfView(coordinates[0]);
    }
    let newSidebar: ILeftSidebar = {};

    // Switches between modals when clicking on left sidebar
    for (const value in leftSidebar) {
      if (value === option) {
        newSidebar[value] = { active: leftSidebar[value].active, status: true };
      } else {
        newSidebar[value] = { active: leftSidebar[value].active, status: false };
      }
    }

    if (viewer) {
      if (currentPanorama && option !== 'addHotspot') {
        // Remove created hotspot without refreshing
        viewer.setOnDoubleClick(setNewHotspotLink, false);
        viewer.removeCurrentHotspot(currentPanorama.id);
        setNewHotspot(undefined);
      }
      if (option !== 'moveAround') {
        viewer.setOnDoubleClick(setStartingPoint, false);
        viewer.removeCurrentHotspot(36000);
        setViewEvent(false);
      }
      if (option !== 'addNote') {
        toggleNewNoteEditorMode(false);
      }
      if (option !== 'stillImage') {
        toggleNewImageEditorMode(false);
      }
      if (option !== 'blur') {
        endAnnotationWhenChangedOnSidebar();
      }
    }
    setLeftSidebar(newSidebar);
  };

  useEffect(() => {
    if (config) {
      if (config && config.modules) {
        let leftSidebarObject = { ...leftSidebar };
        for (const menu of config.modules) {
          const menuKey = Object.keys(menu);
          const key = leftSidebar[menuKey[0]];
          // if there is a config for a left sidebar option add it
          if (key) {
            leftSidebarObject[menuKey[0]] = { active: menu[menuKey[0]], status: false };
          }
        }
        setLeftSidebar(leftSidebarObject);
      }
    }
  }, [config]);

  useEffect(() => {
    // Create existing hotspots and annotations
    if (currentPanorama?.id && viewerLoaded) {
      getHotspotList();
      getPanoramaAnnotationsFromId();
    }
  }, [viewerLoaded]);

  useEffect(() => {
    // Load image first time
    getPanoramaList();
  }, [tour]);

  /*************************************        New Hotspot       ***************************************/
  const toggleNewHotspotEditorMode = (state: boolean) => {
    viewer.setOnDoubleClick(setNewHotspotLink, state);
    if (state === false) {
      setNewHotspot(undefined);
      toggleNewHotspotModal();
      setUpdateOnModalClose(!updateOnModalClose);
    }
  };
  /*************************************        New Hotspot       ***************************************/

  /*************************************        New Hotspot Note     ************************************/
  const toggleNewNoteEditorMode = (state: boolean) => {
    setNoteEdit(false);
    viewer.setOnDoubleClick(setNewNoteHotpot, state);
    if (state === false) {
      setAddNoteModal(false);
      viewer.removeCurrentHotspot(currentPanorama.id);
    }
  };
  /*************************************        New Hotspot Note     ************************************/

  /*************************************        New Hotspot Image     ************************************/
  const toggleNewImageEditorMode = (state: boolean) => {
    viewer.setOnDoubleClick(setNewImageHotpot, state);
    if (state === false) {
      setAddImageModal(false);
      viewer.removeCurrentHotspot(currentPanorama.id);
    }
  };

  /**
   * Updates panorama list display in slider
   * @param tourId
   */
  const updatePanoramaList = async () => {
    try {
      const getPanoramasFromId = await getPanoramas(tour.id);
      setPanoramaData(getPanoramasFromId.sort((a, b) => a.order - b.order));
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  /**
   * Displays and updates the hotspots
   * @param deleteHotspot
   */
  const getHotspotList = async (deleteHotspot?: string) => {
    if (deleteHotspot) {
      viewer.removeCurrentHotspot(deleteHotspot);
    }
    try {
      const hotspotsData = await getHotspotsPlatform(currentPanorama.id);
      const hotspots = mapHotspotValuesPlatform(hotspotsData);
      for (const hotspot in hotspots) {
        viewer.removeCurrentHotspot(hotspots[hotspot].id);
        // Create hotspots
        const modifyHotspot = addHotspotHandlersEditor(hotspots[hotspot]);
        viewer.addNewHotspot(modifyHotspot);
      }
    } catch (error) {
      setViewerLoaded(false);
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const addHotspotHandlersEditor = (hotspot: IHotspotCreation) => {
    const hotspotTemp: IHotspot = {
      pitch: hotspot.pitch,
      yaw: hotspot.yaw,
      type: hotspot.type,
      text: hotspot.roomName,
      id: hotspot.id || '',
      cssClass: hotspot.cssClass,
      createTooltipFunc: hotspot.createTooltipFunc,
      createTooltipArgs: hotspot.createTooltipArgs,
    };

    // Panorama link
    if (hotspot.typeFrontViewer === 0) {
      hotspotTemp.doubleClickHandlerFunc = () => editCustomHotSpot('editHotspot', { ...hotspot, srcId: hotspot.srcId || '0' });
      hotspotTemp.singleClickHandlerFunc = () => changePanoramaUsingId(hotspot.destId, hotspot);
      return hotspotTemp;
    }

    // Media
    if (hotspot.typeFrontViewer === 1 || hotspot.typeFrontViewer === 3) {
      hotspotTemp.singleClickHandlerFunc = () => console.log('');
      hotspotTemp.doubleClickHandlerFunc = () => editMedia(hotspot);
      return hotspotTemp;
    }

    // URL
    if (hotspot.typeFrontViewer === 4) {
      hotspotTemp.singleClickHandlerFunc = () => openLink(hotspot.destId !== hotspot.srcId ? hotspot.destId : '');
      hotspotTemp.doubleClickHandlerFunc = () => editCustomHotSpot('note', hotspot);
      return hotspotTemp;
    }

    // NOTE
    if (hotspot.typeFrontViewer === 6) {
      hotspotTemp.singleClickHandlerFunc = () => console.log('');
      hotspotTemp.doubleClickHandlerFunc = () => editCustomHotSpot('note', hotspot);
    }

    return hotspotTemp;
  };

  const getPanoramaList = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      let startingPanorama;

      let tourId;

      if (tour?.id) {
        tourId = tour.id;
        setPreviousPaginationPage(tour.paginationPage);
        setPreviousWordSearch(tour.searchWord);
        setPreviousTourIndex(tour.tourIndex);
        setOrderOptionName(tour.orderOptionName);
        setOrderOption(tour.orderOption);
        setDashboardView(tour.dashboardView);
      } else {
        // Get from url param
        const tourIdFromParams = params.get('tourId');
        tourId = tourIdFromParams;
        const getTourFromId = await getTour(tourIdFromParams || '');
        setTour(getTourFromId);
      }

      const panoramaIdFromParams = params.get('panoramaId');
      if (panoramaIdFromParams) {
        startingPanorama = panoramaIdFromParams;
      }

      const getPanoramaFromId: IPanorama[] = await getPanoramas(tourId);
      setPanoramaData(getPanoramaFromId.sort((a, b) => a.order - b.order));

      let panoramaToDisplay;
      let panoramaOptions!: IPanoramaOptions[];

      /* If edit all displays first panorama
	  if chosen panorama looks for the corresponding one and displays it*/
      if (tour && startingPanorama) {
        for (const panorama of getPanoramaFromId) {
          if (panorama.id === startingPanorama) {
            panoramaToDisplay = panorama;
            panoramaOptions = panorama.editOptions;
          }
        }
      } else {
        // get the first panorama
        panoramaToDisplay = getPanoramaFromId[0];
        panoramaOptions = getPanoramaFromId[0].editOptions;
      }

      if (panoramaToDisplay) {
        let options: any = {};
        // Set incoming panorama option values to state
        for (let i = 0; i < panoramaOptions.length; i++) {
          const optionKey = panoramaOptions[i].panoramaKey.replace('EDITION_', '').toLowerCase();
          if (optionKey === 'auto') {
            if (panoramaOptions[i].panoramaValue === '1') {
              options[optionKey] = true;
            } else {
              options[optionKey] = false;
            }
          } else {
            options[optionKey] = Number(panoramaOptions[i].panoramaValue);
          }
        }

        setPresetValues(options);
        setImageOptionsResponse(options);

        if (panoramaToDisplay.cubeMap.length) {
          setEditedImage(panoramaToDisplay.cubeMap);
        } else {
          setIsLoading(true);
        }

        setCurrentPanorama(panoramaToDisplay);
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const updateAfterProcessing = async () => {
    const getPanoramasFromId = await getPanoramas(tour.id);
    setPanoramaData(getPanoramasFromId.sort((a, b) => a.order - b.order));
    const current = getPanoramasFromId.find((panorama: IPanorama) => panorama.id === currentPanorama.id);
    if (current) {
      setCurrentPanorama(current);
    }
  };

  /***************************************         New hotspot       *******************************************************/

  const setNewHotspotLink = (coordinates: number[]) => {
    viewer.removeCurrentHotspot(currentPanorama.id);

    const hotspot = getHotspotLinkObjectNoToolTip(coordinates[0], coordinates[1], currentPanorama.id);

    // Add hotspot to viewer
    setNewHotspot({ ...hotspot, name: currentPanorama.name });
    viewer.addNewHotspot(hotspot);
  };

  /***************************************         New hotspot Note      *******************************************************/

  const setNewNoteHotpot = (coordinates: number[]) => {
    viewer.removeCurrentHotspot(currentPanorama.id);

    const hotspot = getHotspotNoteObject(coordinates[0], coordinates[1], currentPanorama.id);
    // Add hotspot to viewer
    setNoteHotspot(hotspot);
    viewer.addNewHotspot(hotspot);
    setAddNoteModal(true);
  };

  /***************************************         New hotspot Image      *******************************************************/

  const setNewImageHotpot = (coordinates: number[]) => {
    viewer.removeCurrentHotspot(currentPanorama.id);

    const hotspot = getHotspotImageObject(coordinates[0], coordinates[1], currentPanorama.id);
    setStillImageNoteHotspot(hotspot);

    // Add hotspot to viewer
    viewer.addNewHotspot(hotspot);
    setAddImageModal(true);
  };

  /**
   * Change panoramaData state order of panoramas
   */
  const changePanoramaOrderState = (newPanoramaDataOrder: IPanorama[]) => {
    setPanoramaData(newPanoramaDataOrder);
  };

  /***************************************         State changing props for Panorama Slider       *******************************************************/

  const changePanorama = (panorama: IPanorama, currentStateHd?: boolean) => {
    const hdState = currentStateHd !== undefined ? currentStateHd : panoramaHdState;
    let tempPanorama: IPanorama;
    if (hdState) {
      tempPanorama = {
        ...panorama,
        cubeMap: panorama.cubeMap.map((url: string) => {
          return url.replace(/(front|right|back|left|upnologo|downnologo)(?=\.jpg)/g, match => `${match}hd`);
        }),
      };
    } else {
      tempPanorama = {
        ...panorama,
        cubeMap: panorama.cubeMap.map((url: string) => {
          return url.replace(/(front|right|back|left|upnologo|downnologo)hd(?=\.jpg)/g, match => match.replace('hd', ''));
        }),
      };
    }
    setRenderImagePresets(false);
    setAnnotationListBlur({ type: 'RESET_ITEM' });
    const params = new URLSearchParams(window.location.search);
    const imageEnv = params.get('imageEnv');
    if (imageEnv) {
      window.history.pushState('', '/editing-viewer/', `?tourId=${tour.id}&panoramaId=${panorama.id}&imageEnv=${imageEnv}`);
    } else {
      window.history.pushState('', '/editing-viewer/', `?tourId=${tour.id}&panoramaId=${panorama.id}`);
    }

    const panoEditOptions: IPanoramaOptions[] = panorama.editOptions;

    let options: any = {};
    // Check if auto enhance is selected
    for (let i = 0; i < panoEditOptions.length; i++) {
      const optionKey = panoEditOptions[i].panoramaKey.replace('EDITION_', '').toLowerCase();
      if (optionKey === 'auto') {
        if (panoEditOptions[i].panoramaValue === '1') {
          options[optionKey] = true;
        } else {
          options[optionKey] = false;
        }
      } else {
        options[optionKey] = panoEditOptions[i].panoramaValue;
      }
    }

    setPresetValues(options);
    setImageOptionsResponse(options);

    if (panorama.cubeMap.length < 1) {
      setIsLoading(true);
    }

    setCurrentPanorama(tempPanorama);
    changeLeftSidebar('');
    setFieldOfView(0);
    setEditedImage(tempPanorama.cubeMap);
    setViewerLoaded(false);
  };

  const changePanoramaUsingId = (destId: string, hotspot?: IHotspotCreation) => {
    //    Get panorama using dest ID
    const destPanorama = panoramaData.find((pano: IPanorama) => pano.id === destId);

    if (destPanorama) {
      if (hotspot) {
        setTargetYaw(hotspot.targetYaw);
      }
      changePanorama(destPanorama);
    }
  };
  /***************************************               Viewer                 *******************************************************/
  const style: CSSProperties = {
    height: '80vh',
  };

  // This is componentWillUnmount
  const componentWillUnmount = useRef(false);

  useEffect(() => {
    if (editedImage && editedImage.length > 1 && currentPanorama) {
      initViewer();
    }
  }, [editedImage, currentPanorama, updateOnModalClose]);

  useEffect(() => {
    // componentDidMount - Initialize Viewer
    if (viewer) {
      if (editedImage) {
        if (currentPanorama.id) {
          resetAnnotations(annotationList.data, annotationListBlur.data);
        }
        componentWillUnmount.current = true;
      }
    }

    // componentWillUnmount - Destroy Viewer
    return () => {
      if (componentWillUnmount.current) {
        viewer && viewer.destroyViewer();
        setViewerLoaded(false);
        componentWillUnmount.current = false;
      }
    };
  }, [editedImage, updateOnModalClose]);

  const initViewer = () => {
    // code to run on component mount
    let foview = 0;

    if (targetYaw) {
      foview = targetYaw;
    } else if (fieldOfView === 0) {
      foview = currentPanorama.startPoint ? getCorrectedYaw(currentPanorama.version, currentPanorama.startPoint) : 0;
    } else {
      foview = fieldOfView;
    }

    const config: IViewerConfig = {
      type: 'cubemap',
      cubeMap: editedImage,
      autoLoad: true,
      northOffset: 247.5,
      showControls: false,
      crossOrigin: 'anonymous',
      doubleClickZoom: false,
      hotSpots: [],
      // Max Zoom out
      hfov: 120,
      // Set Starting point
      yaw: foview,
      // Debug viewer - display coordinates on console log
      // hotSpotDebug: true,
    };

    viewer = new Viewer('panorama', config);

    setIsLoading(true);

    viewer.setOnLoad(stopLoading);

    viewer.setOnError(stopLoading);

    if (viewEvent) {
      viewer.setOnDoubleClick(setStartingPoint, true);
    }
  };

  const stopLoading = () => {
    setIsLoading(false);
    setViewerLoaded(true);
    setTargetYaw(undefined);
  };

  /***************************************               Viewer                   ****************************************************** */

  /*********************  annotations ****************************/
  const newListReducer = newCreateListReducer<IHotspot, string>();
  const [annotationList, setAnnotationList] = useReducer(newListReducer, { data: [] });
  const [addHotspotEvent, setAddHotspotEvent] = useState(false);
  const [object, setObject] = useState<any>([]);
  const [isAnnotating, setIsAnnotating] = useState(false);
  // Blur annotation
  const newListReducerBlur = newCreateListReducerBlur<IHotspot, string>();
  const memoizedReducer = useCallback(newListReducerBlur, []);
  const [annotationListBlur, setAnnotationListBlur] = useReducer(memoizedReducer, { data: [] });
  const [addHotspotEventBlur, setAddHotspotEventBlur] = useState(false);
  const [objectBlur, setObjectBlur] = useState<any>([]);
  const [isAnnotatingBlur, setIsAnnotatingBlur] = useState(false);

  const cssClasses: any = {
    default: 'pnlm-default-hotspot',
    door: 'pnlm-door-hotspot',
    window: 'pnlm-window-hotspot',
    wall_corner: 'pnlm-wall-corner-hotspot',
    // vertical_line: 'pnlm-dynamic-hotspot',
    // blur_area: 'pnlm-dynamic-hotspot',
    stairs: 'pnlm-stairs-hotspot',
    // dynamic: 'pnlm-dynamic-hotspot',
  };

  const startAnnotation = () => {
    const state = !addHotspotEvent;
    viewer.setOnDoubleClick(createAnnotation, state);
    setAddHotspotEvent(state);
  };

  const startAnnotationBlur = () => {
    const state = !addHotspotEventBlur;
    viewer.setOnDoubleClick(createAnnotationBlur, state);
    setAddHotspotEventBlur(state);
  };

  const finishAnnotation = async () => {
    const state = !addHotspotEvent;
    viewer.setOnDoubleClick(createAnnotation, state);
    setAddHotspotEvent(state);
    if (object.length > 0) {
      setAnnotationList({ type: 'ADD_ITEM', item: object });
      // TODO Refactor
      const annotation = annotationParser(object);
      const response = await createAnnotationPanoramaAsync(annotation);
      if (response) {
        updateNewPanorama(response, object);
      }
      setObject([]);
    }
  };

  const finishAnnotationBlur = async () => {
    if (objectBlur.length >= 3) {
      const state = !addHotspotEventBlur;
      viewer.setOnDoubleClick(createAnnotationBlur, state);
      setAddHotspotEventBlur(state);
      if (objectBlur.length >= 3) {
        setAnnotationListBlur({ type: 'ADD_ITEM', item: objectBlur });
        setObjectBlur([]);
      }
    }
  };

  const endAnnotationWhenChangedOnSidebar = () => {
    for (const hotspot in objectBlur) {
      viewer.removeCurrentHotspot(objectBlur[hotspot].id);
    }
    const state = false;
    setAddHotspotEventBlur(state);
    viewer.setOnDoubleClick(createAnnotationBlur, state);
    setObjectBlur([]);
    setIsAnnotatingBlur(false);
    setBlurActive(false);
    setBlurAnnotationsToDelete([]);
  };

  const createAnnotation = (coordinates: number[]) => {
    const id = GenerateUUID();

    const hotspot = getHotspotAnnotationObject(coordinates[0], coordinates[1], id);

    setObject((oldArray: number[]) => [...oldArray, hotspot]);

    viewer.addNewHotspot(hotspot);
  };

  const createAnnotationBlur = (coordinates: number[]) => {
    const id = GenerateUUID();
    const hotspot: ILocalHotspotCreation = {
      status: 0,
      pitch: coordinates[0],
      yaw: coordinates[1],
      type: 'info',
      id: id,
      cssClass: 'pnlm-dynamic-hotspot',
      hotspotLabel: 'blur_area',
      text: `Blur ${annotationListBlur.data.length + 1}`,
      index: annotationListBlur.data.length + 1,
      hotspotColor: '#FFF',
    };
    setObjectBlur((oldArray: number[]) => [...oldArray, hotspot]);
    viewer.addNewHotspot(hotspot);
  };

  const getSuggestionsBlur = async () => {
    setLoadingSuggestion({ state: 'loading' });
    try {
      //Report Custom Category and Event
      ReportCustomEvent(EnumCategory.BlurCreation, EnumEvent.AutoFaceDetection);
      const suggestionsBlur = await getBlurSuggestion(currentPanorama.id);
      const currentBlurs = [...annotationListBlur?.data];
      if (suggestionsBlur && suggestionsBlur.length > 0) {
        const newSuggestions = [];
        for (const suggestBlur of suggestionsBlur) {
          const newSuggest: any = [];
          for (const newSuggestBlur of suggestBlur) {
            const id = GenerateUUID();
            const newAnnotationBlur = {
              cssClass: 'pnlm-dynamic-hotspot',
              hotspotColor: '#FFF',
              hotspotLabel: 'blur_area',
              id: id,
              pitch: newSuggestBlur.pitch,
              text: `Blur ${currentBlurs.length + 1}`,
              index: currentBlurs.length + 1,
              type: 'info',
              yaw: newSuggestBlur.yaw,
            };
            viewer.addNewHotspot(newAnnotationBlur);
            newSuggest.push(newAnnotationBlur);
          }
          currentBlurs.push(newSuggest);
          newSuggestions.push(newSuggest);
        }

        if (newSuggestions && newSuggestions.length > 0) {
          for (const newAnnotations of newSuggestions) {
            setAnnotationListBlur({ type: 'ADD_ITEM', item: newAnnotations });
          }
        }
        setLoadingSuggestion({ state: 'default' });
      } else {
        setLoadingSuggestion({ state: 'empty' });
      }
    } catch (error) {
      setLoadingSuggestion({ state: 'default' });
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const addAnnotations = (annotationList: IPanoramaAnnotation[]) => {
    let hotspotList: any = [];
    let blurList: any = [];
    let blurResponseArray: IReducerHotspot = { data: [] };
    let test = [];
    for (const annotationIndex in annotationList) {
      const points = annotationList[annotationIndex].points;
      for (const point of points) {
        let hotspot: any = {
          pitch: point.pitch,
          yaw: point.yaw,
          type: 'info',
          id: annotationList[annotationIndex].id,
          cssClass: annotationList[annotationIndex].cssClass,
          hotspotLabel: annotationList[annotationIndex].label,
        };

        if (hotspot.hotspotLabel !== 'blur_area') {
          hotspot.text = `pitch: ${point.pitch} - yaw: ${point.yaw}`;
        }

        if (annotationList[annotationIndex].cssClass === 'pnlm-dynamic-hotspot') {
          if (config) {
            const annotationConfig = config.annotationType.find(
              (annotationConfig: IAnnotationTypesConfig) => annotationConfig.value === annotationList[annotationIndex].label,
            );
            if (annotationConfig) {
              hotspot.hotspotColor = annotationConfig.colour;
            }
          }
        }

        if (hotspot.hotspotLabel === 'blur_area') {
          blurList.push(hotspot);
          hotspot.text = `Blur ${Number(annotationIndex) + 1}`;
          hotspot.index = Number(annotationIndex) + 1;
        } else {
          hotspotList.push(hotspot);
        }

        viewer.addNewHotspot(hotspot);
      }

      if (hotspotList.length > 0) {
        setAnnotationList({ type: 'ADD_ITEM', item: hotspotList });
      }

      if (blurList.length > 0) {
        const newListWithStatus: any = [...blurList];
        newListWithStatus[0].status = 1;
        blurResponseArray.data.push(newListWithStatus);
        test.push(newListWithStatus);
        setAnnotationListBlur({ type: 'ADD_ITEM', item: newListWithStatus });
      }
      blurList = [];
      hotspotList = [];
    }
    // Save original response to the compare with edited one
    // Create a copy of the array without referencing (will not be updating)
    setBlurResponse(createArrayCopyNoReference(test));
    blurResponseArray = { data: [] };
  };

  const onViewerAction = (action: string) => {
    switch (action) {
      case 'annotate':
        const state = !isAnnotating;
        if (state) {
          startAnnotation();
          setIsAnnotating(state);
        } else {
          finishAnnotation();
          setIsAnnotating(state);
        }
        break;
    }
  };

  const onViewerActionBlur = (action: string) => {
    switch (action) {
      case 'annotate':
        const state = !isAnnotatingBlur;
        if (state) {
          startAnnotationBlur();
          setIsAnnotatingBlur(state);
        } else {
          finishAnnotationBlur();
          setIsAnnotatingBlur(state);
        }
        break;
    }
  };

  const editCustomHotSpot = (menuOption: string, hotspotData: IHotspotCreation) => {
    changeLeftSidebar(menuOption);
    setSelectedHotspot(hotspotData);
    if (menuOption === 'note') {
      setNoteEdit(true);
      setAddNoteModal(true);
    }
  };

  const editMedia = (hotspot: IHotspotCreation) => {
    setSelectedHotspot(hotspot);
    setAddImageModal(true);
    setMediaEdit(true);
    changeLeftSidebar('stillImage');
  };

  const handleRemove = async (item: IHotspotCreation, id: string) => {
    const removeHotpots = annotationList.data[id];
    // TODO - add id on a higher level
    let annotationId = '';
    for (const hotspot in removeHotpots) {
      annotationId = removeHotpots[hotspot].id;
      viewer.removeCurrentHotspot(annotationId);
    }
    setAnnotationList({ type: 'REMOVE_SHAPE', item });

    await deletePanoramaAnnotationFromId(annotationId);
  };

  const handleRemoveBlur = async (item: IHotspotCreation, id: string) => {
    const removeHotpots = annotationListBlur.data[id];
    // TODO - add id on a higher level
    let annotationId = '';
    for (const hotspot in removeHotpots) {
      annotationId = removeHotpots[hotspot].id;
      viewer.removeCurrentHotspot(annotationId);
    }
    setAnnotationListBlur({ type: 'REMOVE_SHAPE', item });

    setBlurAnnotationsToDelete([...blurAnnotationsToDelete, annotationId]);
  };

  const handleUpdateHotspotColour = async (index: number, newLabel: string) => {
    const currentHotspots = annotationList.data[index];
    const cssClass: string = cssClasses[newLabel] || 'pnlm-dynamic-hotspot';
    // TODO Refactor
    let annotation = { points: [] as any, cssClass: '', label: '' };
    let annotationId = '';
    let newColour;

    for (const hotspot in currentHotspots) {
      const updateHotspot = currentHotspots[hotspot];
      if (updateHotspot.hotspotLabel !== 'link') {
        viewer.removeCurrentHotspot(updateHotspot.id);
        const createHotspot: IHotspot = {
          pitch: updateHotspot.pitch,
          yaw: updateHotspot.yaw,
          type: 'info',
          text: updateHotspot.text,
          id: updateHotspot.id,
          cssClass: cssClasses[newLabel] || 'pnlm-dynamic-hotspot',
          hotspotLabel: newLabel,
        };

        if (!cssClasses[newLabel]) {
          if (config) {
            newColour = config.annotationType.find((annotationConfig: IAnnotationTypesConfig) => annotationConfig.value === newLabel);
            if (newColour) {
              createHotspot.hotspotColor = newColour.colour;
            }
          }
        }

        // TODO Refactor
        annotation.points.push({ pitch: updateHotspot.pitch, yaw: updateHotspot.yaw });
        annotation.cssClass = cssClasses[newLabel] || 'pnlm-dynamic-hotspot';
        annotation.label = newLabel;
        annotationId = updateHotspot.id;

        viewer.addNewHotspot(createHotspot);
      }
    }

    if (newColour) {
      setAnnotationList({
        type: 'UPDATE_HOTSPOT_VALUE',
        index,
        newColour: newLabel,
        cssClass,
        hotspotColor: newColour.colour,
      });
    } else {
      setAnnotationList({ type: 'UPDATE_HOTSPOT_VALUE', index, newColour: newLabel, cssClass });
    }

    await updatePanoramaAnnotationFromId(annotationId, annotation);
  };

  const getPanoramaAnnotationsFromId = async () => {
    try {
      const annotations = await getPanoramaAnnotations(currentPanorama.id);
      addAnnotations(annotations);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const deletePanoramaAnnotationFromId = async (id: string) => {
    try {
      await deletePanoramaAnnotation(id);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const updatePanoramaAnnotationFromId = async (id: string, annotation: IUpdateAnnotation) => {
    try {
      await updatePanoramaAnnotation(id, annotation);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const createAnnotationPanoramaAsync = async (annotation: IAnnotation[]) => {
    try {
      const response = await createPanoramaAnnotation(annotation);
      if (response) {
        return response;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const annotationParser = (annotationList: INewAnnotation[], blur?: boolean) => {
    let annotation: any = {};
    let annotationPoints = [];
    annotation.id = currentPanorama.id;
    for (const point of annotationList) {
      annotationPoints.push({ pitch: point.pitch, yaw: point.yaw });
      annotation.cssClass = point.cssClass;
      annotation.label = blur ? 'blur_area' : point.hotspotLabel;
    }
    annotation.points = annotationPoints;
    return annotation;
  };

  const updateNewPanorama = (response: IPanoramaAnnotation, item: INewAnnotation[]) => {
    // Remove copy
    for (const hotspot of item) {
      viewer.removeCurrentHotspot(hotspot.id);
    }
    setAnnotationList({ type: 'REMOVE_SHAPE', item: item });

    let hotspotList: any = [];
    for (const annotation of item) {
      let hotspot: IHotspot = {
        pitch: annotation.pitch,
        yaw: annotation.yaw,
        type: 'info',
        text: `pitch: ${annotation.pitch} - yaw: ${annotation.yaw}`,
        id: response.id,
        cssClass: annotation.cssClass,
        hotspotLabel: annotation.label,
      };
      hotspotList.push(hotspot);

      viewer.addNewHotspot(hotspot);
    }
    setAnnotationList({ type: 'ADD_ITEM', item: hotspotList });
    hotspotList = [];
  };

  // Removes annotations when panorama is changed
  const resetAnnotations = (annotationList: INewAnnotation[][], annotationListBlur: INewAnnotation[][]) => {
    // TODO - add id on a higher level
    for (const annotation of annotationList) {
      for (const hotspot of annotation) {
        viewer.removeCurrentHotspot(hotspot.id);
      }
      setAnnotationList({ type: 'REMOVE_SHAPE', item: annotation });
    }

    for (const annotationBlur of annotationListBlur) {
      for (const hotspot of annotationBlur) {
        viewer.removeCurrentHotspot(hotspot.id);
      }
      setAnnotationListBlur({ type: 'RESET_ITEM' });
    }
  };

  /*********************  annotations ****************************/

  /************************ starting point panorama ***********************/

  const [viewEvent, setViewEvent] = useState(false);
  const [panoramaStartingPoint, setPanoramaStartingPoint] = useState(0);
  const [isStartingPointSetted, setIsStartingPointSetted] = useState(false);
  const [isStartingPointButtonEnable, setIsStartingPointButtonEnable] = useState(true);
  // eslint-disable-next-line
  const [visible, setVisible, toggle] = useModal();

  function setStartingPoint(coordinates: number[]) {
    const id = 36000;

    const currentPoint = coordinates[1];

    viewer.removeCurrentHotspot(36000);

    const yaw = getWrongYaw(currentPanorama.version, currentPoint);

    setPanoramaStartingPoint(yaw);

    setIsStartingPointSetted(true);
    setIsStartingPointButtonEnable(true);

    //Hotspot object
    const hotspot: IHotspot = {
      pitch: 0,
      yaw: currentPoint,
      type: 'info',
      id: id,
      hotspotLabel: 'default',
      cssClass: 'starting-point-panorama',
    };

    // Add hotspot to viewer
    viewer.addNewHotspot(hotspot);
  }

  const onSetView = async (panoramaId: string) => {
    const state = !viewEvent;

    if (panoramaId !== currentPanorama.id) {
      changePanoramaUsingId(panoramaId);
    } else {
      viewer.setOnDoubleClick(setStartingPoint, state);
    }
    if (viewEvent === false) setIsStartingPointButtonEnable(false);
    if (state === false) {
      toggle();
    }
    setViewEvent(state);
  };

  const onSetViewCancel = () => {
    toggle();
    setIsStartingPointSetted(false);
    viewer.removeCurrentHotspot(36000);
  };

  const onSetViewAccepted = async () => {
    toggle();
    if (panoramaData.length > 0) {
      setIsStartingPointSetted(false);
      const body = {
        startPoint: panoramaStartingPoint,
      };
      viewer.removeCurrentHotspot(36000);
      const response = await updatePanorama(currentPanorama.id, body);
      if (response) toggleSuccessNotification('Panorama starting point set');
      updatePanoramaList();
    }
  };

  /************************ starting point panorama ***********************/

  /************************ Exit editor modal ***********************/

  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  function exitEditor(): void {
    toggleModal();
    // verify origin
    if (previousLocationHistory && previousLocationHistory.displayDashboard) {
      // displayDashboard
      history.push(`/`, {
        tourIndex: previousLocationHistory.tourIndex,
        pagination: previousLocationHistory.paginationPage,
        wordSearch: previousLocationHistory.searchWord,
        orderOptionName: previousLocationHistory.orderOptionName,
        orderOption: previousLocationHistory.orderOption,
        dashboardView: previousLocationHistory.dashboardView,
      });
    } else if (
      previousWordSearch !== undefined &&
      previousPaginationPage !== undefined &&
      previousTourIndex !== undefined &&
      orderOptionName !== undefined &&
      orderOption !== undefined &&
      dashboardView !== undefined
    ) {
      history.push(`/`, {
        tourIndex: previousTourIndex,
        pagination: previousPaginationPage,
        wordSearch: previousWordSearch,
        orderOptionName: previousLocationHistory,
        orderOption: previousLocationHistory,
        dashboardView: dashboardView,
      });
    } else {
      // tour code pasted
      history.push(`/`);
    }
  }

  /************************ Exit editor modal ***********************/

  /************************ Delete panorama modal ***********************/

  const [deletePanoramaModal, setDeletePanoramaModal, toggleDeletePanoramaModal] = useModal();
  const [currentDeletePanorama, setCurrentDeletePanorama] = useState<string>('');

  const deleteCurrentPanoramaOnModal = (panoramaId: string) => {
    toggleDeletePanoramaModal();
    setCurrentDeletePanorama(panoramaId);
  };

  const deleteCurrentPanorama = async () => {
    toggleDeletePanoramaModal();
    try {
      const response = await deletePanorama(currentDeletePanorama);
      setPanoramaDeleteResponse(response);
      setPosition(position + 5);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const onCancelModal = () => {
    setDeletePanoramaModal(false);
    setCurrentDeletePanorama('');
  };

  useEffect(() => {
    // Load image first time
    if (panoramaDeleteResponse) {
      getPanoramaList();
    }
  }, [panoramaDeleteResponse]);

  /************************ Delete panorama modal ***********************/

  const reprocessAfterImageEditing = async () => {
    changeLeftSidebar('');
    setEditedImage([]);
    setIsLoading(true);
    try {
      await savePanoramaEdited(currentPanorama.id, presetValues);
      await reprocessPanorama(currentPanorama.id);
      updateAfterProcessing();
      setReprocessForImageEdit(true);
      // Display notification message
      setTimeout(() => {
        setReprocessForImageEdit(false);
      }, 5000);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  /************************ Reprocess panorama after blurring ***********************/

  const reprocessPanoramaForBlur = async () => {
    changeLeftSidebar('');
    setEditedImage([]);
    setIsLoading(true);
    try {
      // Delete annotations removed
      if (blurAnnotationsToDelete.length > 0) {
        for (const blurDelete of blurAnnotationsToDelete) {
          await deletePanoramaAnnotationFromId(blurDelete);
        }
        setBlurAnnotationsToDelete([]);
      }

      //   Save new annotations
      for (const blurAnnotations of annotationListBlur.data) {
        if (!blurAnnotations[0].status) {
          const blur = true;
          const annotation = annotationParser(blurAnnotations, blur);
          const createdBlur = await createAnnotationPanoramaAsync(annotation);
          if (createdBlur) {
            updateNewPanoramaBlur(createdBlur, blurAnnotations);
          }
        }
      }

      setReprocessingPanorama(true);
      await reprocessPanorama(currentPanorama.id);
      updateAfterProcessing();
      setTimeout(() => {
        setReprocessingPanorama(false);
      }, 5000);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const updateNewPanoramaBlur = (response: IPanoramaAnnotation, item: INewAnnotation[]) => {
    // Remove copy
    for (const hotspot of item) {
      viewer.removeCurrentHotspot(hotspot.id);
    }

    setAnnotationListBlur({ type: 'REMOVE_SHAPE', item: item });

    let hotspotList: any = [];
    for (const annotation of item) {
      let hotspot = {
        pitch: annotation.pitch,
        yaw: annotation.yaw,
        type: 'info',
        id: response.id,
        cssClass: annotation.cssClass,
        hotspotLabel: 'blur_area',
        hotspotColor: annotation.hotspotColor,
        status: 1,
        text: `Blur ${annotationListBlur.data.length + 1}`,
        index: annotationListBlur.data.length + 1,
      };

      hotspotList.push(hotspot);

      viewer.addNewHotspot(hotspot);
    }
    setAnnotationListBlur({ type: 'ADD_ITEM', item: hotspotList });
    hotspotList = [];
  };

  const sendEmail = async (tourId: string, emails: string[]) => {
    ReportCustomEvent(EnumCategory.EmailTour, EnumEvent.SendEmail);
    try {
      await shareTour(tourId, emails);
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
    }
  };

  const switchPanoramaHd = () => {
    const currentState = !panoramaHdState;
    setPanoramaHdState(currentState);
    changePanorama(currentPanorama, currentState);
  };

  console.log(currentPanorama.processingVersion);

  if (tour) {
    return (
      <React.Fragment>
        {currentPanorama.processingVersion > 6 && isBeta ? (
          !panoramaHdState ? (
            <button onClick={switchPanoramaHd} className='hd-button button-hover'>
              Enable HD
            </button>
          ) : (
            <button onClick={switchPanoramaHd} className='hd-button button-hover'>
              Disable HD
            </button>
          )
        ) : null}
        {showNotification && (
          <Notification
            showNotification={showNotification}
            size={EnumNotificationSize.Large}
            type={typeNotification || EnumNotificationType.Info}
            message={notificationMessage}
            setShowNotification={setShowNotification}
            fullScreen={true}
            duration={notificationDuration}
          />
        )}
        <Steps
          enabled={isTooltipActive}
          steps={tooltipSteps}
          initialStep={currentTooltipStep}
          onAfterChange={stepsInfo}
          onComplete={onStepsCompleted}
          onExit={onExit}
          options={{ hideNext: false, exitOnOverlayClick: false, showStepNumbers: false, showBullets: false, disableInteraction: true }}
        />
        {config && (
          <div className='left-toolbar'>
            <LeftSidebar
              currentPanorama={currentPanorama}
              changeLeftSidebar={changeLeftSidebar}
              leftSidebar={leftSidebar}
              toggleNewHotspotEditorMode={toggleNewHotspotEditorMode}
              toggleNewNoteEditorMode={toggleNewNoteEditorMode}
              toggleNewImageEditorMode={toggleNewImageEditorMode}
            />
          </div>
        )}
        <div className='image-edit-top-menu'>
          <Header
            sidebar={sidebar}
            currentPanorama={currentPanorama}
            changeLeftSidebar={changeLeftSidebar}
            tour={tour}
            updatePanoramaList={updatePanoramaList}
            exitEditor={toggleModal}
            activeTooltipHandler={activeTooltipHandler}
            isTooltipActive={isTooltipActive}
          />
        </div>
        <PanoramaSlider
          tour={tour}
          setEditedImage={setEditedImage}
          setCurrentPanorama={setCurrentPanorama}
          panoramaData={panoramaData}
          changePanoramaOrderState={changePanoramaOrderState}
          currentPanorama={currentPanorama}
          leftSidebar={leftSidebar}
          changeLeftSidebar={changeLeftSidebar}
          changePanorama={changePanorama}
          updatePanoramaList={updatePanoramaList}
          deletePanorama={deleteCurrentPanoramaOnModal}
          onSetView={onSetView}
          position={position}
          setPosition={setPosition}
          setPresetValues={setPresetValues}
          setImageOptionsResponse={setImageOptionsResponse}
        />
        {/* ALL MODALS */}
        {leftSidebar.note.status && !addNoteModal && (
          <div className='info-box'>
            <img src={infoIcon} alt='Info Icon' />
            <p>
              <Trans id='Double-click on the image to select a spot for your note icon' />
            </p>
          </div>
        )}
        <div className={`${addNoteModal ? 'show' : 'hide'}`}>
          <AddNote
            noteEdit={noteEdit}
            note={noteHotspot}
            selectedHotspot={selectedHotspot}
            changeLeftSidebar={changeLeftSidebar}
            toggleNewNoteEditorMode={toggleNewNoteEditorMode}
            getHotspotList={getHotspotList}
            toggleSuccessNotification={toggleSuccessNotification}
          />
        </div>
        {newHotspotModal && (
          <AddHotspot
            tour={tour}
            currentPanorama={currentPanorama}
            panoramaData={panoramaData}
            changeLeftSidebar={changeLeftSidebar}
            newHotspot={newHotspot}
            getHotpostList={getHotspotList}
            toggleNewHotspotEditorMode={toggleNewHotspotEditorMode}
            updatePanoramaList={updatePanoramaList}
          />
        )}
        {leftSidebar.addHotspot.status && (
          <button
            disabled={!newHotspot ? true : false}
            className={`info-box ${!newHotspot ? '' : 'info-box-active'}`}
            onClick={() => {
              viewer.removeCurrentHotspot(currentPanorama.id);
              toggleNewHotspotModal();
            }}
          >
            {!newHotspot ? (
              <>
                <img src={infoIcon} alt='Add Hotspot information' />
                <p>
                  <Trans id='Double-click anywhere on the image to create a new link' />
                </p>
              </>
            ) : (
              <>
                <img src={tickIcon} alt='Add Hotspot information' />
                <p>
                  <Trans id='Click here to confirm link position' />
                </p>
              </>
            )}
          </button>
        )}
        {leftSidebar.audio.status && (
          <AddAudio panorama={currentPanorama} changeLeftSidebar={changeLeftSidebar} toggleNotification={toggleNotification} />
        )}
        {viewEvent && (
          <button
            disabled={isStartingPointButtonEnable ? false : true}
            className={`info-box ${isStartingPointButtonEnable ? 'info-box-active' : ''}`}
            onClick={() => {
              onSetView(currentPanorama.id);
            }}
          >
            {isStartingPointSetted ? (
              <>
                <img src={starIcon} alt='Select Starting Point' />
                <p>
                  <Trans id='Select Starting point' />
                </p>
              </>
            ) : (
              <>
                <img src={infoIcon} alt='Info Icon' />
                <p>
                  <Trans id='Double-click anywhere on the photo to select Staring point' />
                </p>
              </>
            )}
          </button>
        )}
        {leftSidebar.stillImage.status && !addImageModal && (
          <div className='info-box'>
            <img src={infoIcon} alt='Info Icon' />
            <p>
              <Trans id='Double-click anywhere to select a spot for your still image or media icon' />
            </p>
          </div>
        )}
        <div className={`${leftSidebar.addImage.status ? 'show' : 'hide'}`}>
          <AddImage tour={tour} panoramaData={panoramaData} changeLeftSidebar={changeLeftSidebar} getPanoramaList={getPanoramaList} />
        </div>
        <div className={`${addImageModal ? 'show' : 'hide'}`}>
          <StillImage
            selectedHotspot={selectedHotspot}
            mediaEdit={mediaEdit}
            setMediaEdit={setMediaEdit}
            stillImageHotspot={stillImageHotspot}
            changeLeftSidebar={changeLeftSidebar}
            getHotspotList={getHotspotList}
            toggleSuccessNotification={toggleSuccessNotification}
          />
        </div>
        <div className={`${leftSidebar.editHotspot.status ? 'show' : 'hide'}`}>
          <EditHotspot
            currentPanorama={currentPanorama}
            changeLeftSidebar={changeLeftSidebar}
            panoramaData={panoramaData}
            selectedHotspot={selectedHotspot}
            toggleSuccessNotification={toggleSuccessNotification}
            getHotspotList={getHotspotList}
            updatePanoramaList={updatePanoramaList}
          />
        </div>
        <div className={`${leftSidebar.share.status ? 'show' : 'hide'}`}>
          {/* <Share changeLeftSidebar={changeLeftSidebar} tourData={tour} /> */}
          <ShareModal
            hideModal={() => changeLeftSidebar('')}
            modalContent={{
              id: 'share-tour',
              title: linguiTranslateToString('Share your virtual tour'),
              tour: tour,
            }}
            shareTourLink={sendEmail}
            toggleNotification={toggleNotification}
            deleteTourMatch={() => console.log()}
          />
        </div>
        <div className={`${leftSidebar.linksMap.status ? 'show' : 'hide'}`}>
          <LinksMap
            tourId={tour?.id}
            panoramaData={panoramaData}
            changeLeftSidebar={changeLeftSidebar}
            changePanorama={changePanorama}
            toggleNewHotspotEditorMode={toggleNewHotspotEditorMode}
            leftSidebar={leftSidebar}
          />
        </div>
        <div className={`${leftSidebar.settings.status ? 'show' : 'hide'}`}>
          <Settings
            sidebar={sidebar}
            tour={tour}
            changeLeftSidebar={changeLeftSidebar}
            toggleNotification={toggleNotification}
            leftSidebar={leftSidebar}
            panoramaData={panoramaData}
            currentPanorama={currentPanorama}
            updatePanoramaList={updatePanoramaList}
          />
        </div>
        <div className='image-editing-container'>
          <div className={`add-annotation ${leftSidebar.annotation.status ? 'show' : 'hide'}`}>
            <AddAnnotation
              annotateActive={annotateActive}
              toggleAnnotation={toggleAnnotation}
              hotspots={annotationList}
              handleRemove={handleRemove}
              handleUpdateHotspotColour={handleUpdateHotspotColour}
              changeLeftSidebar={changeLeftSidebar}
            />
          </div>
          {reprocessingPanorama && (
            <button className='info-box'>
              <img src={infoIcon} alt='Info Icon' />
              <p>
                <Trans id='Your blur is being processed. It takes up to 1 min. Continue editing your tour.' />
              </p>
            </button>
          )}
          {reprocessForImageEdit && (
            <button className='info-box'>
              <img src={infoIcon} alt='Info Icon' />
              <p>
                <Trans id='Your edition is being processed. It takes up to 1 min. Continue editing your tour.' />
              </p>
            </button>
          )}
        </div>
        {blurActive && leftSidebar.blur.status && (
          <button className='info-box'>
            <img src={infoIcon} alt='Info Icon' />
            <p>
              <Trans id='Double-click on the photo to position various blur points ' />
            </p>
          </button>
        )}
        <div className='image-editing-container'>
          <div
            className={`add-blur ${leftSidebar.blur.status ? 'show' : 'hide'}`}
            style={{ height: sidebar.panoramaBlur ? '494px' : '541px' }}
          >
            <AddBlur
              sidebar={sidebar}
              loadingSuggestion={loadingSuggestion}
              blurActive={blurActive}
              toggleBlur={toggleBlur}
              hotspotsBlur={annotationListBlur}
              blurResponse={blurResponse}
              handleRemoveBlur={handleRemoveBlur}
              changeLeftSidebar={changeLeftSidebar}
              reprocessPanoramaForBlur={reprocessPanoramaForBlur}
              getSuggestionsBlur={getSuggestionsBlur}
              objectBlur={objectBlur}
            />
          </div>
        </div>
        <div className='image-editing-container'>
          {/* Set Starting point modal  */}
          <Modal
            visible={visible}
            text='The view you have selected will be used as a Starting point of your image'
            tittle='Starting point confirmation'
            onFirstBtn={onSetViewCancel}
            onSecondBtn={onSetViewAccepted}
            onFirstBtnLabel='Cancel'
            onSecondBtnLabel='Confirm'
          />
          {/* Set Starting point modal  */}
          {/* Exit editor modal  */}
          <Modal
            visible={itemModalOpen}
            tittle={linguiTranslateToString('Exit Editor')}
            text={linguiTranslateToString('Are you sure you want to exit the Editor?')}
            onFirstBtn={() => setItemModalOpen(false)}
            onSecondBtn={exitEditor}
            onFirstBtnLabel={linguiTranslateToString('Return to Editor')}
            onSecondBtnLabel={linguiTranslateToString('Exit')}
          />
          {/* Exit editor modal  */}
          {/* Delete panorama modal */}
          <Modal
            visible={deletePanoramaModal}
            tittle={linguiTranslateToString('Delete image')}
            text={linguiTranslateToString('Are you sure you want to delete this image?')}
            onFirstBtn={onCancelModal}
            onSecondBtn={deleteCurrentPanorama}
            onFirstBtnLabel={linguiTranslateToString('Cancel')}
            onSecondBtnLabel={linguiTranslateToString('Delete Image')}
          />
          {/* Delete panorama modal */}
          <SuccessNotification
            display={toggleSuccess}
            notificationMessage={notificationMessage}
            toggleSuccessNotification={toggleSuccessNotification}
          />
          {/* Viewer */}
          {/* <button
            onClick={() => {
              // Get viewer configuration
              console.log(viewer.getConfig());
            }}
          >
            Test Scene
          </button> */}
          <div id='panorama' style={style} />
          {isLoading && <LoadingSpinnerNoBg />}
          {/* Viewer */}
          <div className={`edit-image ${leftSidebar.editImage.status ? 'show' : 'hide'}`}>
            <EditImage
              sidebar={sidebar}
              imageEditStatus={leftSidebar.editImage.status}
              editedImage={editedImage}
              currentPanorama={currentPanorama}
              resetPresets={resetPresets}
              reprocessAfterImageEditing={reprocessAfterImageEditing}
              changeLeftSidebar={changeLeftSidebar}
              presetValues={presetValues}
              setPresetValues={setPresetValues}
              imageOptionsResponse={imageOptionsResponse}
              renderImagePresets={renderImagePresets}
              setRenderImagePresets={setRenderImagePresets}
            />
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <LoadingSpinner />;
  }
};

export default EditingViewer;
